import {
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AuthContext } from "../../api/AuthProvider";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  createTheme,
} from "@mui/material";
import MyListPage from "./Pages/Gifts/MyListPage";
import MyHousesPage from "./Pages/HouseHolds/MyHousesPage";
import ContactPage from "../ContactPage/ContactPage";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import MyParticipationsPage from "./Pages/MyParticipationsPage";
import HouseDetailPage, {
  OutlinedButton,
} from "./Pages/HouseHolds/HouseDetailPage";
import ExternalUserPageList from "./Pages/Gifts/ExternalUserPageList";
import WebAppNavBar from "./WebAppNavBar/WebAppNavBar";
import GiftPage from "./Pages/Gifts/GiftPage";
import AddGiftPage from "./Pages/Gifts/AddGiftPage";
import CreateHouseholdPage from "./Pages/HouseHolds/CreateHouseholdPage";
import JoinHouseholdPage from "./Pages/HouseHolds/JoinHouseholdPage";
import MyProfilePage from "./Pages/Profile/MyProfilePage";
import SettingsPage from "./Settings/SettingsPage";
import {
  SettingsContext,
} from "../../utils/SettingsProvider";
import PrivacyPolicy from "../PrivacyPolicy";
import {
  getFirebaseToken,
  onForegroundMessage,
} from "../../firebase";
import { apiFcmTokenClient } from "../../api/api";
import { getNotificationInfosFromPayload } from "../../utils/NotificationHelper";
import { openDB } from "idb";
import { useNotificationPermission } from "../../utils/UsePermission";
import { MyPaperPropsDialog } from "../ReusableComponents/MyWaitingDialog";
import {
  getFcmToken,
  setNotifDialogShown,
  storeFcmToken,
} from "../../localStorageUtils";
import SecretSantaPage from "./Pages/HouseHolds/SecretSanta/SecretSantaPage";
import { enqueueSnackbar } from "notistack";

export default function FamilyGiftWebApp() {
  let auth = useContext(AuthContext);
  let navigation = useLocation();
  let navigate = useNavigate();
  let settings = useContext(SettingsContext);

  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const innerTheme = createTheme(theme);

  const permissionNotif = useNotificationPermission();

  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then((firebaseToken) => {
        if (firebaseToken) {
          var currentfcm = getFcmToken();
          if (currentfcm !== firebaseToken) {
            apiFcmTokenClient.deleteToken(auth?.token ?? "", currentfcm ?? "");
            apiFcmTokenClient.storeToken(auth?.token ?? "", firebaseToken);
            storeFcmToken(firebaseToken);
          }
          return;
        }
      })
      .catch((err) =>
        console.error("An error occured while retrieving firebase token. ", err)
      );
  };

  async function storeDatasInDb() {
    const db = await openDB("settings_notifications_table", 1.0, {
      upgrade(database, oldVersion, newVersion, transaction, event) {
        database.createObjectStore("settings_notifications");
      },
    });
    if (permissionNotif != "granted") {
      settings?.setAddGiftNotifications(false);
      settings?.setBirthdayNotifications(false);
      settings?.setDeleteGiftNotifications(false);
      settings?.setGeneralNotifications(false);
      settings?.setSecretSantaNotifications(false);
    }
    await db.put(
      "settings_notifications",
      settings?.birthdayNotifications ?? false,
      "birthdayNotifications"
 );
    await db.put(
      "settings_notifications",
      settings?.generalNotifications ?? false,
      "generalNotifications"
    );
    await db.put(
      "settings_notifications",
      settings?.deleteGiftNotifications ?? false,
      "deleteGiftNotifications"
    );
    await db.put(
      "settings_notifications",
      settings?.secretSantaNotifications ?? false,
      "secretSantaNotifications"
    );
    await db.put(
      "settings_notifications",
      settings?.addGiftNotifications ?? false,
      "addGiftNotifications"
    );

    await db.put(
      "settings_notifications",
      t("giftAdded"),
      "translate_giftAdded"
    );
    await db.put(
      "settings_notifications",
      t("xAddedXonHisList"),
      "translate_xAddedXonHisList"
    );
    await db.put(
      "settings_notifications",
      t("xAddedXonXList"),
      "translate_xAddedXonXList"
    );

    await db.put(
      "settings_notifications",
      t("giftDeleted"),
      "translate_giftDeleted"
    );
    await db.put(
      "settings_notifications",
      t("xDeletedXonHisList"),
      "translate_xDeletedXonHisList"
    );
    await db.put(
      "settings_notifications",
      t("xDeletedXonXList"),
      "translate_xDeletedXonXList"
    );
    await db.put(
      "settings_notifications",
      t("yourParticipationForXedited"),
      "translate_yourParticipationForXedited"
    );
    await db.put(
      "settings_notifications",
      t("yourParticipationForXeditedBody"),
      "translate_yourParticipationForXeditedBody"
    );



    await db.put(
      "settings_notifications",
      t("askerNeedHelpTitle"),
      "translate_askerNeedHelpTitle"
    );
    await db.put(
      "settings_notifications",
      t("askerNeedHelpBody"),
      "translate_askerNeedHelpBody"
    );
    await db.put(
      "settings_notifications",
      t("xCreatedSecretSanta"),
      "translate_xCreatedSecretSanta"
    );
    await db.put(
      "settings_notifications",
      t("secretSantaCreationBody"),
      "translate_secretSantaCreationBody"
    );
    await db.put(
      "settings_notifications",
      t("xDeletedSecretSanta"),
      "translate_xDeletedSecretSanta"
    );
    await db.put(
      "settings_notifications",
      t("secretSantaDeletionBody"),
      "translate_secretSantaDeletionBody"
    );
    await db.put(
      "settings_notifications",
      t("exclusionFromX"),
      "translate_exclusionFromX"
    );
    await db.put(
      "settings_notifications",
      t("youExcludedBy"),
      "translate_youExcludedBy"
    );
    await db.put(
      "settings_notifications",
      t("xExcludedBy"),
      "translate_xExcludedBy"
    );
    await db.put(
      "settings_notifications",
      t("todayIsTheDay"),
      "translate_todayIsTheDay"
    );
    await db.put(
      "settings_notifications",
      t("birthdaySoon"),
      "translate_birthdaySoon"
    );
    await db.put(
      "settings_notifications",
      t("itsXbirthday2weeks"),
      "translate_itsXbirthday2weeks"
    );
    await db.put(
      "settings_notifications",
      t("itsXbirthday"),
      "translate_itsXbirthday"
    );
    await db.put(
      "settings_notifications",
      t("itsXbirthday1week"),
      "translate_itsXbirthday1week"
    );
  
  }

  useEffect(() => {
    if (permissionNotif == "granted") {
      handleGetFirebaseToken();
    }
  }, [permissionNotif]);

  useEffect(() => {
    storeDatasInDb();
  }, [
    settings?.addGiftNotifications,
    settings?.generalNotifications,
    settings?.deleteGiftNotifications,
    settings?.birthdayNotifications,
    settings?.secretSantaNotifications,
    permissionNotif,
    t
  ]);

  const [notifReceived, setNotifReceived] = useState(0);

  useEffect(() => {
    onForegroundMessage()
      .then((payload) => {
        var datas = payload.data;
        var category = datas.category;
        var notifInfos = getNotificationInfosFromPayload(payload, t);
        let show_notif = false;

        switch (category) {
          case "ajout2":
            show_notif = settings?.addGiftNotifications ?? false;
            break;
          case "suppression2":
            show_notif = settings?.deleteGiftNotifications ?? false;
            break;
          case "price_updated":
            show_notif = settings?.deleteGiftNotifications ?? false;
            break;
          case "help_required":
            show_notif = settings?.addGiftNotifications ?? false;
            break;
          case "createdSecretSanta":
            show_notif = settings?.secretSantaNotifications ?? false;
            break;
          case "deletedSecretSanta":
            show_notif = settings?.secretSantaNotifications ?? false;
            break;
          case "userExcluded":
            show_notif = settings?.generalNotifications ?? false;
            break;
          case "birthday2":
            show_notif = settings?.birthdayNotifications ?? false;
            break;
          default:
            show_notif = false;
            break;
        }

        if (show_notif) {
          var notif = new Notification(notifInfos.title, {
            body: notifInfos.textBody,
            image: "/assets/family_gift_icon.png",
            icon: "/assets/family_gift_icon.png",
          });
          notif.onclick = function (event) {
            event.preventDefault();
            window.open(notifInfos.deeplink, "_blank");
          };
        }
        setNotifReceived(notifReceived + 1);
      })
      .catch((err) =>
        console.log(
          "An error occured while retrieving foreground message. ",
          err
        )
      );
  }, [
    notifReceived,
    settings?.addGiftNotifications,
    settings?.generalNotifications,
    settings?.deleteGiftNotifications,
    settings?.birthdayNotifications,
    settings?.secretSantaNotifications,
  ]);

  useEffect(() => {
    console.log("PATH", navigation)
    if (auth?.userConnected) {
      if (navigation.pathname == "/app/" || navigation.pathname == "/app") {
        navigate("myList", { replace: true });
      }
    } else {
      navigate("/login", {state: { pathToGo: navigation.pathname }, replace: true})
    }
  }, [navigation, auth?.userConnected]);


  return (
    <div>
      {auth?.userReconnected ? (
        <>
          <Dialog
            PaperProps={MyPaperPropsDialog}
            open={
              permissionNotif == "default" &&
              (settings?.showNotifDialog ?? true)
            }
          >
            <img
              key={"Family Gift"}
              src="/assets/family_gift_logo_simple.svg"
              alt={"Family Gift"}
              loading="eager"
              draggable="false"
              style={{
                margin: "auto",
                display: "inline-block",
                width: "80px",
                height: "80px",
              }}
            />
            <DialogTitle id="responsive-dialog-title">
              <Typography
                fontWeight={"bold"}
                color={"primary"}
                textAlign={"center"}
              >
                Family Gift utilise les notifications !
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography
                  textAlign={"center"}
                  color={"primary"}
                  fontWeight={"bold"}
                >
                  Family Gift utilise des notifications pour vous aider à vous
                  organiser. Les notifications utilisées sont purement
                  informationnelles et ne seront jamais commerciales. Elles ne
                  sont générées que par l'activité de vos proches, et rien
                  d'autre !
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <OutlinedButton
                variant="outlined"
                autoFocus
                onClick={() => {
                  settings?.setShowNotifDialog(false);
                  setNotifDialogShown(false);
                }}
              >
                Annuler
              </OutlinedButton>
              <OutlinedButton
                color="primary"
                variant="outlined"
                onClick={() => {
                  window.Notification.requestPermission();
                  settings?.setShowNotifDialog(false);
                  setNotifDialogShown(false);
                }}
                autoFocus
              >
                Valider
              </OutlinedButton>
            </DialogActions>
          </Dialog>
          <WebAppNavBar />
          <Routes>
            <Route path="myList" element={<MyListPage />} />
            <Route path="households">
              <Route index={true} element={<MyHousesPage />}></Route>
              <Route path=":houseId">
                <Route index={true} element={<HouseDetailPage />}></Route>
                <Route path="secretSanta" element={<SecretSantaPage />}>
                  {" "}
                </Route>
              </Route>
              <Route path="create" element={<CreateHouseholdPage />}></Route>
              <Route
                path="join/:houseCode"
                element={<JoinHouseholdPage />}
              ></Route>
            </Route>
            <Route path="participations" element={<MyParticipationsPage />} />
            <Route path="profile" element={<MyProfilePage />} />
            <Route path="settings">
              <Route index={true} element={<SettingsPage />} />
              <Route path="privacy" element={<PrivacyPolicy />}></Route>
              <Route path="contact" element={<ContactPage />}></Route>
            </Route>
            <Route path="user/:userId" element={<ExternalUserPageList />} />
            <Route path="gift/:giftId" element={<GiftPage />} />
            <Route path="gift/add" element={<AddGiftPage />} />
          </Routes>
        </>
      ) : (
        <Typography>{t("reconnecting")}</Typography>
      )}
    </div>
  );
}

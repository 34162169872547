import { init } from "i18next";
import { useEffect, useState } from "react"
import { saveAddGiftNotifications, saveBirthdayNotifications } from "../localStorageUtils";

 export const useNotificationPermission = () => {
    const [permission, setPermission] = useState(Notification.permission);
    let oldPermission = permission
    navigator.permissions.query({name: 'notifications'}).then(function(newPermission) {  
        // Initial status is available at permission.state
        console.log("initialisation permission");
        oldPermission = permission
        newPermission.onchange = function() {  
            console.log("permission notification changed !!!");
            if(newPermission.state != oldPermission) setPermission(Notification.permission);
        };
      });

    return permission
 }
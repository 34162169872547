import React, { useContext, useState } from "react";
import { CreateHouseDto, House } from "../../../../models/House";
import { useTheme } from "@emotion/react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
import { AuthContext } from "../../../../api/AuthProvider";
import MyWaitingDialog, {
  MyPaperPropsDialog,
} from "../../../ReusableComponents/MyWaitingDialog";
import UploadableNetworkImage from "../../../ReusableComponents/UploadableNetworkImage";
import { enqueueSnackbar } from "notistack";
import { apiClient } from "../../../../api/api";
import { User, UserLightWithoutWishlist } from "../../../../models/User";
import { OutlinedButton } from "../HouseHolds/HouseDetailPage";
import {
  UpdateUserDto,
  UserChildDto,
} from "../../../../models/apiBody/BodyLoginWithMail";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/fr";
import "dayjs/locale/en";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarIcon, DateField, DatePicker } from "@mui/x-date-pickers";
import { HouseVisibilityItem } from "../Gifts/AddGiftPage";
import { AxiosError } from "axios";
function EditChildDialog(props: {
  open: boolean;
  onClose: (user: User | null) => void;
  child: UserLightWithoutWishlist;
}) {
  let [userName, setUserName] = useState(props.child.username ?? "");
  let [userPicture, setUserPicture] = useState(props.child.photoUrl ?? "");
  const [birthday, setBirthday] = useState<Dayjs | null>(
      dayjs(props.child.birthdayDate).isValid() ? dayjs(props.child.birthdayDate) : null
  );
  const [houseVisible, setHouseVisible] = useState<number[]>(
    props.child.houses.map((item, i) => {
      return item.id;
    })
  );

  let [editInProgress, setEditInProgress] = useState(false);
  let [askDelete, setAskDelete] = useState(false);
  let [deleteInProgress, setDeleteInrogress] = useState(false);

  let theme = useTheme();
  const innerTheme = createTheme(theme);
  const auth = useContext(AuthContext);
  const today = dayjs();
  const { t, i18n } = useTranslation();
  var languageCodeUsed = i18n.language.split("-")[0];

  return (
    <>
    <Dialog
      PaperProps={MyPaperPropsDialog}
      open={askDelete}
      scroll="body"
      fullWidth
    >
      <MyWaitingDialog
        open={deleteInProgress}
        message={t("removingX", {value: userName})}
      />
      <DialogTitle id="responsive-dialog-title">
        <Typography fontWeight={"bold"} color={"primary"} textAlign={"center"}>
          {t("removeXFromYourAccount",{value: props.child.username})}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        {t("removeXFromYourAccountExplain",{value: props.child.username})}
      </DialogContent>
      
      <DialogActions>
        <OutlinedButton
          variant="outlined"
          onClick={() => {
            setAskDelete(false);
          }}
        >
          {t("no")}
        </OutlinedButton>
        <OutlinedButton
          color="error"
          variant="outlined"
          onClick={() => {
            setDeleteInrogress(true);
            setAskDelete(false)

            apiClient
              .deleteChildren(
                auth?.token ?? "",
                props.child.id
              )
              .then((response) => {
                var newUser = response.data;
                setEditInProgress(false);
                props.onClose(null);
                auth?.refreshUser()
                enqueueSnackbar(t("childSuccessfullyRemoved"), { variant: "success" });
              })
              .catch((error: AxiosError) => {
                setEditInProgress(false);
                enqueueSnackbar(
                  t("childRemovedFailed", {value: error.response?.status}),
                  {
                    variant: "error",
                  }
                );
              });
          }}
        >
          {t("yes")}
        </OutlinedButton>
      </DialogActions>
      
    </Dialog>
    <Dialog
      PaperProps={MyPaperPropsDialog}
      open={props.open}
      scroll="body"
      fullWidth
    >
      <MyWaitingDialog
        open={editInProgress}
        message={t("updatingX", {value: userName})}
      />
      <DialogTitle id="responsive-dialog-title">
        <Typography fontWeight={"bold"} color={"primary"} textAlign={"center"}>
          {t("updateXProfile", {value: props.child.username})}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          overflow={"visible"}
        >
          <Box width={"150px"} height={"150px"} overflow={"visible"}>
            <UploadableNetworkImage
              initialUrl={userPicture}
              alt={"Téléchargez la photo de votre enfant"}
              onUrlChange={(url) => {
                setUserPicture(url);
              }}
            />
          </Box>
        </Box>

        <TextField
          required
          error={(userName ?? "").length == 0}
          variant="outlined"
          value={userName}
          onChange={(e) => {
            setUserName(e.target.value);
          }}
          fullWidth
          color="primary"
          type="text"
          InputProps={{
            style: {
              borderRadius: "16px",
            },
          }}
          label={t("nameOfYourChild")}
          sx={{
            marginTop: "16px",
            background: "white",
            borderRadius: "16px",
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
        />

        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={languageCodeUsed}
        >
          <DatePicker
            localeText={{
              toolbarTitle: t("childBirthdate"),
              okButtonLabel: t("validate"),
              cancelButtonLabel: t("cancel"),
              clearButtonLabel: t("clear"),
            }}
            maxDate={today}
            formatDensity="spacious"
            label={t("childBirthdate")}
            value={birthday}
            slotProps={{
              actionBar: {
                actions: ["clear", "cancel", "accept"],
              },
              textField: {
                InputProps: {
                  style: {
                    borderRadius: "16px",
                  },
                },
                fullWidth: true,
                sx: {
                  marginTop: "16px",
                  background: "white",
                  borderRadius: "16px",
                },
              },
              toolbar: {
                hidden: false,
              },
            }}
            onChange={(newValue) => setBirthday(newValue)}
          />
        </LocalizationProvider>
        <Typography
            fontWeight={"bold"}
            paddingTop={"16px"}
            color={"primary"}
            textAlign={"center"}
          >
            {t("visibleHomes")}
          </Typography>
          <Typography
            textAlign={"center"}
          >
            {t("houseVisibilityChildExplain")}
          </Typography>
          <Box
                    sx={{
                      width: "100%",
                      padding:"8px",
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {auth?.user?.houses
                      .sort((a, b) => {
                        if (a.name < b.name) {
                          return -1;
                        }
                        if (a.name > b.name) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((item, _) => {
                        return (
                          <HouseVisibilityItem
                            house={item}
                            key={item.id}
                            selected={
                              houseVisible?.find(
                                (itemId) => item?.id === itemId
                              ) != null
                            }
                            onClick={(event) => {
                              var selected =
                              houseVisible?.find(
                                  (itemId) => item?.id === itemId
                                ) != null;
                              if (selected)
                                setHouseVisible(
                                  houseVisible?.filter(
                                    (itemId) => itemId !== item.id
                                  )
                                );
                              else
                              setHouseVisible(
                                  houseVisible?.concat(item.id)
                                );
                            }}
                          />
                        );
                      })}
                  </Box>
      </DialogContent>
      <DialogActions>
      <OutlinedButton color="error" sx={{boxSizing:"border-box", width:"100%"}} onClick={() => {setAskDelete(true)}}>
        {t("removeXFromYourAccount", {value: props.child.username})}
      </OutlinedButton>
      </DialogActions>
      <DialogActions>
        <OutlinedButton
          variant="outlined"
          onClick={() => {
            props.onClose(null);
          }}
        >
          {t("cancel")}
        </OutlinedButton>
        <OutlinedButton
          color="primary"
          variant="outlined"
          onClick={() => {
            setEditInProgress(true);
            const birthdayString = birthday?.format();
            apiClient
              .updateChildren(
                new UserChildDto(
                  userName,
                  userPicture,
                  birthdayString ?? "",
                  houseVisible
                ),
                auth?.token ?? "",
                props.child.id
              )
              .then((response) => {
                var newUser = response.data;
                setEditInProgress(false);
                props.onClose(newUser);
                enqueueSnackbar(t("profileUpdated"), { variant: "success" });
              })
              .catch((error) => {
                setEditInProgress(false);
                enqueueSnackbar(
                  t("errorUpdatingChildProfile"),
                  {
                    variant: "error",
                  }
                );
              });
          }}
          disabled={(userName ?? "").length == 0}
        >
          {t("validate")}
        </OutlinedButton>
      </DialogActions>
      
    </Dialog>
    </>
  );
}

export default EditChildDialog;

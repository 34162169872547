import axios, { AxiosError, AxiosResponse } from 'axios';
import { ApiToken, ApiTokenApple, BodyLoginWithFacebook, BodyLoginWithGoogle, BodyLoginWithMail, BodyRefreshTokenWithApple, BodySigninWithApple, BodySignupWithMail, ExcludeHouseDto, MailBodyResetPassword, UpdateUserDto, UserChildDto } from '../models/apiBody/BodyLoginWithMail';
import { PasswordBody, User, UserInfoWithResetToken } from '../models/User';
import { ParticipationGiftDto } from '../models/Participation';
import { AddGiftDto, Gift, ProductInfos, UpdateGiftDto, UrlBody } from '../models/Gift';
import { CreateHouseDto, CreateSecretSantaDto, House, JoinHouseDtp } from '../models/House';

//export const baseUrl = "https://dev.api.family-gift.fr:8443/family_gift/"
//export const baseUrl = "https://192.168.1.25:8080/family_gift/"
export const baseUrl = "https://api.family-gift.fr:4000/family_gift/"

const apiClientService = axios.create({
    baseURL: baseUrl,
    timeout: 15000,
    headers: {
        'Content-Type': 'application/json',
      },
});

export const apiClient = {
    login: async (data: BodyLoginWithMail): Promise<AxiosResponse<ApiToken>> => {
            return await apiClientService.post('/login', data);
    },
    signupWithMail: async (data: BodySignupWithMail): Promise<AxiosResponse<void>> => {
        return await apiClientService.post('/signup', data);
    },
    loginWithGoogle: async (data: BodyLoginWithGoogle) : Promise<AxiosResponse<ApiToken>> => {
        return await apiClientService.post('/loginFromGoogle', data)
    },
    signinWithGoogle: async (data: BodyLoginWithGoogle) : Promise<AxiosResponse<void>> => {
        return await apiClientService.post('/signupWithGoogle', data)
    },
    loginWithFacebook: async (data: BodyLoginWithFacebook) : Promise<AxiosResponse<ApiToken>> => {
        return await apiClientService.post('/newLoginFromFacebook', data)
    },
    signinWithFacebook: async (data: BodyLoginWithFacebook) : Promise<AxiosResponse<void>> => {
        return await apiClientService.post('/newSignupWithFacebook', data)
    },
    signinWithApple: async (data: BodySigninWithApple) : Promise<AxiosResponse<ApiTokenApple>> => {
        return await apiClientService.post('/signupWithAppleWeb', data)
    },
    loginWithApple: async (data: BodySigninWithApple) : Promise<AxiosResponse<ApiTokenApple>> => {
        return await apiClientService.post('/loginWithAppleWeb', data)
    },
    refreshWithApple: async (data: BodyRefreshTokenWithApple) : Promise<AxiosResponse<ApiTokenApple>> => {
        return await apiClientService.post('/refreshTokenAppleReact', data)
    },
    getUserWithToken: async (token: string) : Promise<AxiosResponse<User>> => {
        return await apiClientService.get("/user", {
            headers: {
                "token": token
            }
        })
    },
    
    getMyParticipations: async (token: string) : Promise<Gift[]> => {
        return await apiClientService.get("/getMyParticipations", {
            headers: {
                "token": token
            }
        }).then((response: AxiosResponse<Gift[]>) => {
            return response.data
        });
    },

    getHouseById: async (token: string, houseId: string | undefined) : Promise<House> => {
        return await apiClientService.get('/house/getById', {
            params:{
                "houseId": `${houseId}`
            },
            headers: {
                "token": token
            }
        }).then((response: AxiosResponse<House>) => {
            return response.data
        });
    },

    getHouseByCode: async (token: string, houseCode: string | undefined) : Promise<House> => {
        return await apiClientService.get('/house/getByCode', {
            params:{
                "houseCode": `${houseCode}`
            },
            headers: {
                "token": token
            }
        }).then((response: AxiosResponse<House>) => {
            return response.data
        });
    },

    getGiftById: async (token: string, giftId: string | undefined) : Promise<Gift> => {
        return await apiClientService.get('/getGiftById', {
            params:{
                "idGift": `${giftId}`
            },
            headers: {
                "token": token
            }
        })
        .catch((e: AxiosError) => {
            throw e;
        })
        .then((response: AxiosResponse<Gift>) => {
            return response.data
        });
    },

    getUserById: async (token: string, userId: string | undefined) : Promise<User> => {
        return await apiClientService.get('/userById', {
            params:{
                "userId": `${userId}`
            },
            headers: {
                "token": token
            }
        }).then((response: AxiosResponse<User>) => {
            return response.data
        });
    },

    getProductInfos: async (data: UrlBody, token: string) : Promise<AxiosResponse<ProductInfos>> => {
        return await apiClientService.post('getGiftInfosFromUrl', data, {
            headers:{
                "token": token
            }
        })
    },
    
    addGiftOnList: async (data: AddGiftDto, token: string, userIdToAdd: number) : Promise<AxiosResponse<Gift>> => {
        return await apiClientService.post('addGiftToUser', data, {
            headers:{
                "token": token
            },
            params:{
                "idUser": userIdToAdd
            }
        })
    },

    sendMailToResetPassword: async (data: MailBodyResetPassword) : Promise<AxiosResponse<any>> => {
        return await apiClientService.post('sendResetPasswordMail', data)
    },

    createHousehold: async (data: CreateHouseDto, token: string) : Promise<AxiosResponse<House>> => {
        return await apiClientService.post('house/create', data, {
            headers:{
                "token": token
            }
        })
    },

    leaveHousehold: async (token: string, houseCode: string) : Promise<AxiosResponse<any>> => {
        return await apiClientService.post('house/leave', null,{
            headers:{
                "token": token
            }, 
            params:{
                "houseCode": houseCode
            }
            
        })
    },
    excludeHousehold: async (token: string, excludeDto: ExcludeHouseDto) : Promise<AxiosResponse<void>> => {
        return await apiClientService.post('house/exclude', excludeDto,{
            headers:{
                "token": token
            }, 
            
        })
    },
    joinHousehold: async (data: JoinHouseDtp, token: string, houseCode: string) : Promise<AxiosResponse<House>> => {
        return await apiClientService.post('house/join', data, {
            headers:{
                "token": token
            },
            params:{
                "houseCode": houseCode
            }
        })
    },
    editHousehold: async (data: CreateHouseDto, houseCode: string, token: string) : Promise<AxiosResponse<House>> => {
        return await apiClientService.put('house/editHouse', data, {
            headers:{
                "token": token
            },
            params:{
                "houseCode": houseCode
            }
        })
    },
    participateToGift: async (body: ParticipationGiftDto, token: string) : Promise<AxiosResponse<Gift>> => {
        return await apiClientService.post('participateToAGift', body, {
            headers:{
                "token": token
            },
        })
    },
    updateUser: async (body: UpdateUserDto, token: string) : Promise<AxiosResponse<User>> => {
        return await apiClientService.post('updateUser', body, {
            headers:{
                "token": token
            },
        })
    },
    updateChildren: async (body: UserChildDto, token: string, childrenId: number) : Promise<AxiosResponse<User>> => {
        return await apiClientService.post('updateChildren', body, {
            headers:{
                "token": token
            },
            params:{
                "childrenId": childrenId
            }
        })
    },
    createChild: async (body: UserChildDto, token: string) : Promise<AxiosResponse<User>> => {
        return await apiClientService.post('createChildren', body, {
            headers:{
                "token": token
            }
        })
    },
    
    deleteChildren: async (token: string, childrenId: number) : Promise<AxiosResponse<User>> => {
        return await apiClientService.get('deleteChildren', {
            headers:{
                "token": token
            },
            params:{
                "childrenId": childrenId
            }
        })
    },
    deleteUser: async (token: string) : Promise<AxiosResponse<void>> => {
        return await apiClientService.get('deleteUserWithToken', {
            headers:{
                "token": token
            }
        })
    },

    askInfoUserResetPassword: async (token: string) : Promise<AxiosResponse<UserInfoWithResetToken>> => {
        return await apiClientService.get('getInfoWithReinitialisationToken', {
            params:{
                "token": token
            }
        })
    },

    actuallyResetPassword: async (token: string, newPassword: string) : Promise<AxiosResponse<void>> => {
        return await apiClientService.post('resetPassword', new PasswordBody(newPassword) ,{
            params:{
                "token": token
            }
        })
    },

    askForHelp: async (token: string, idGift: number): Promise<AxiosResponse<void>> => {
        return await apiClientService.post('helpForThisGift', null, {
            headers:{
                "token": token
            },
            params:{
                "idGift": idGift
            }
        })
    },

};

export const apiHouseClient = {

    createSecretSanta: async (token: string, houseId: number, data: CreateSecretSantaDto) : Promise<AxiosResponse<House>> => {
        return await apiClientService.post('newCreateSecretSanta', data, {
            headers:{
                "token": token
            },
            params:{
                "houseId": houseId
            }
        })
    },
    stopSecretSanta: async (token: string, houseId: number) : Promise<AxiosResponse<House>> => {
        return await apiClientService.post('deleteSecretSanta', null, {
            headers:{
                "token": token
            },
            params:{
                "houseId": houseId
            }
        })
    },
};

export const apiFcmTokenClient = {
    storeToken: async (token: string, fcmToken: string) : Promise<AxiosResponse<void>> => {
        return await apiClientService.post('storeFcmTokenForUser', null, {
            headers:{
                "token": token,
                "fcmToken": fcmToken
            }
        })
    },
    deleteToken: async (token: string, fcmToken: string) : Promise<AxiosResponse<void>> => {
        return await apiClientService.post('deleteFcmTokenForUser', null, {
            headers:{
                "token": token,
                "fcmToken": fcmToken
            }
        })
    },
}

export const apiGiftClient = {
    deleteGift: async (token: string, idGift: number) : Promise<AxiosResponse<void>> => {
        return await apiClientService.post('deleteGift', null, {
            headers:{
                "token": token
            },
            params:{
                "idGift": idGift
            }
        })
    },
    updateGift: async (token: string, idGift: number, data: UpdateGiftDto) : Promise<AxiosResponse<Gift>> => {
        return await apiClientService.put('updateGift', data, {
            headers:{
                "token": token
            },
            params:{
                "idGift": idGift
            }
        })
    },}


  
export default apiClientService;
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../api/AuthProvider";
import {
  Gift,
  giftComparatorAlpha,
  giftComparatorOldToRecent,
  giftComparatorPrice,
  giftComparatorRate,
  giftComparatorRecentToOld,
} from "../../../../models/Gift";
import { Box, Grid, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTheme } from "@emotion/react";
import {
  HandHoldingHeart,
  IconGift,
  IconHouseUser,
} from "../../WebAppNavBar/WebAppNavBar";
import { useNavigate } from "react-router-dom";
import { ActualExternalList } from "./ExternalUserPageList";
import AvatarPicture from "../../../ReusableComponents/AvatarPicture";
import useSessionStorage from "../../../../utils/useSessionStorage";
import MyGiftItem from "../../../ReusableComponents/MyGiftItem";
import MyFab from "../../../ReusableComponents/MyFab";
import SortOrderMenu from "../../../ReusableComponents/SortOrderMenu";
import { SettingsContext, SortOrder } from "../../../../utils/SettingsProvider";
import CarouselTutorial from "../../../ReusableComponents/CarouselTutorial";
import { useTranslation } from "react-i18next";

function MyListPage() {
  var auth = useContext(AuthContext);
  const [value, setValue] = useSessionStorage(
    "value_tabs_my_list",
    auth?.user?.id.toString()
  );

  useEffect(() => {
    if(value == null){
      setValue(auth?.user?.id.toString())
    }
  }, [auth?.user])

  const {t} = useTranslation();

  useEffect(() => {
    var listIds = [auth?.user?.id];
    auth?.user?.children.forEach((child) => listIds.push(child.id));
    if (!listIds.includes(Number.parseInt(value)) || value == undefined) {
      setValue(auth?.user?.id.toString());
    }
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue.toString());
  };

  const theme = useTheme();

  return (
    <div className="list-page">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          overflow: "visible",
          boxSizing: "border-box",
          justifyContent: "space-between",
          padding: "8px",
        }}
      >
        <Typography variant="h4" color="primary" padding={"8px"}>
          {auth?.user?.children.length == 0 ? t("myList") : t("myLists") }
        </Typography>
        <SortOrderMenu large={false} />
      </Box>
      {auth?.user ? (
        auth.user.children.length == 0 ? (
          <MyActualList />
        ) : (
          <>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={handleChange}
                >
                  <Tab
                    label={t("myList")}
                    iconPosition="start"
                    key={auth.user.id}
                    icon={
                      <Box width={50} height={50} key={auth.user.id}>
                        <AvatarPicture
                          alt={auth.user.username}
                          url={auth.user.photoUrl}
                        />
                      </Box>
                    }
                    value={auth.user.id.toString()}
                  />
                  {auth.user.children.map((item, i) => {
                    return (
                      <Tab
                        key={item.id}
                        iconPosition="start"
                        icon={
                          <Box width={50} height={50}>
                            <AvatarPicture
                              alt={item.username}
                              url={item.photoUrl}
                            />
                          </Box>
                        }
                        label={t("xList",{value: item.username})}
                        value={item.id.toString()}
                      />
                    );
                  })}
                </TabList>
              </Box>
              <TabPanel value={auth.user.id.toString()}>
                <MyActualList />
              </TabPanel>
              {auth.user.children.map((item, i) => {
                return (
                  <TabPanel value={item.id.toString()} key={item.id}>
                    <ActualExternalList
                      key={item.id}
                      userId={item.id.toString()}
                      showHeader={false}
                    />
                  </TabPanel>
                );
              })}
            </TabContext>
          </>
        )
      ) : (
        <></>
      )}
    </div>
  );
}

function MyActualList() {
  const {t} = useTranslation();
  document.title = t("myList");
  const navigate = useNavigate();
  var settings = useContext(SettingsContext);
  var auth = useContext(AuthContext);
  const [data, setData] = useState(auth?.user?.wishList ?? []);

  useEffect(() => {
    var list = auth?.user?.wishList.filter((item) => item.addedBy.id == auth?.user?.id) ?? [];
    switch (settings?.sortOrder) {
      case SortOrder.ALPHABETICALLY:
        setData([...list.sort(giftComparatorAlpha)]);
        break;
      case SortOrder.PRICE:
        setData([...list.sort(giftComparatorPrice)]);
        break;
      case SortOrder.RATE:
        setData([...list.sort(giftComparatorRate)]);
        break;
      case SortOrder.OLD_TO_RECENT:
        setData([...list.sort(giftComparatorOldToRecent)]);
        break;
      case SortOrder.RECENT_TO_OLD:
        setData([...list.sort(giftComparatorRecentToOld)]);
        break;
      default:
        setData([...list.sort(giftComparatorOldToRecent)]);
        break;
    }
  }, [auth?.user]);

  useEffect(() => {
    switch (settings?.sortOrder) {
      case SortOrder.ALPHABETICALLY:
        setData([...data.sort(giftComparatorAlpha)]);
        break;
      case SortOrder.PRICE:
        setData([...data.sort(giftComparatorPrice)]);
        break;
      case SortOrder.RATE:
        setData([...data.sort(giftComparatorRate)]);
        break;
      case SortOrder.OLD_TO_RECENT:
        setData([...data.sort(giftComparatorOldToRecent)]);
        break;
      case SortOrder.RECENT_TO_OLD:
        setData([...data.sort(giftComparatorRecentToOld)]);
        break;
      default:
        setData([...data.sort(giftComparatorOldToRecent)]);
        break;
    }
  }, [settings?.sortOrder]);

  var carouselItemsEmptyList = [
    {
      title: t("myListIsVeryEmpty"),
      subtitle:t("youCanAddIdeasOnYourList"),
      icon: "../assets/family_gift_logo_simple.svg",
    },
    {
      title:t("youtFindTheGiftOnInternet"),
      subtitle:t("familyGiftGetsInformations"),
      icon: "../assets/share.svg",
    },
    {
      title: t("lackingInspiration"),
      subtitle:t("relativesCanAddIdeas"),
      icon: "../assets/edit-list.svg",
    },
    {
      title: t("listUsefulWhenRelativesCanSeeIt"),
      subtitle:t("lovedOnesVisibility"),
        icon: "../assets/share.svg",
    },
  ];

  return (
    <Box>
      <MyFab
        onClick={() => {
          navigate(`/app/gift/add`, { state: { user: auth?.user } });
        }}
        Icon={IconGift}
        text={t("addIdeaOnMyList")}
      />
      {data.length == 0 ? (
        <CarouselTutorial items={carouselItemsEmptyList} />
      ) : (
        <Grid
          marginTop={2}
          container
          sx={{ transition: "all .5s ease-in-out" }}
        >
          {data.map((item, i) => (
            <MyGiftItem key={item.id} gift={item} />
          ))}
        </Grid>
      )}
      <Box height={"70px"}></Box>
    </Box>
  );
}

export function compareGifts(a: Gift, b: Gift) {
  if (a.rate ?? 0 < (b.rate ?? 0)) {
    return 1;
  }
  if (a.rate ?? 0 > (b.rate ?? 0)) {
    return -1;
  }
  return 0;
}

export default MyListPage;
